.MuiButton-textSecondary{
    color: white !important;
    background: #6173fe !important;
}

.MuiCheckbox-colorSecondary.Mui-checked{
    color: #6173fe !important;
}

.MuiDrawer-paperAnchorLeft{
    background: #1e1e2c !important;
}
.MuiIconButton-label{
    background-color: #f0f3fe !important;
    width: 18px !important;
    height: 18px !important;
}
.MuiBackdrop-root{
    background-color: transparent !important;
}
.MuiIconButton-colorSecondary{
    color:  transparent !important;
}
.MuiTypography-body1{
    font-size: 14px !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    /* color: #575962 !important; */
    font-family: Poppins !important;
}
.MuiSwitch-colorSecondary.Mui-checked{
    color: #5d78ff !important;
}

.MuiInput-underline:after{
    border-bottom: 0px !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 0px !important;
}

.MuiInputBase-input{
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.MuiAvatar-img{
    height: auto !important;
}

.MuiPaper-elevation1{
    box-shadow: none !important;
}
.MuiSvgIcon-root{
    height: 16px !important;
    width: 16px !important;
}
.MuiFlatPageButton-rootEnd{
    background-color: #f0f3fe !important;
}
.MuiButton-contained{
    padding: 5px !important;
}